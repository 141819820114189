<template>
  <product title="Amazfit X"
    :swiper-data="swiperData"
    :slogan="slogan"
    :price="price"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://www.xiaomiyoupin.com/detail?gid=136552"
  ></product>
</template>

<script>
import Product from '../Product'

export default {
  name: 'amazfit-x',
  components: {
    Product
  },
  data () {
    return {
      slogan: 'Bow to the Future<br />3D曲面屏幕 | 钛合金机身 | 心率血氧监测',
      colors: [
        '黑色',
        '金色'
      ],
      price: 1999,
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/amazfit-x/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/amazfit-x/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/amazfit-x/1_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/amazfit-x/1_pro_04.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/amazfit-x/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/amazfit-x/2_pro_02.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/01.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/02.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/03.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/04.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/05.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/06.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/07.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/08.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/09.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/10.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/11.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/12.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/13.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/new_14.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/new_15.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/new_16.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/new_17.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/new_18.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/new_19.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/new_20.jpg',
        'https://site-cdn.huami.com/files/amazfit/amazfit-m/amazfit-x/detail/new_21.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
